import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import HomePage from "./HomePage";

const UserRegister = () => {

  const [username, setUsername] = useState('');
  const [clientId, setClientId] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [redirectUri, setRedirectUri] = useState('');

  const userId = localStorage.getItem('userId');

  const handleSubmit = (event) => {

    const data = {
      userid: userId,
      username: username,
      client_id: clientId,
      secret_key: secretKey,
      redirect_uri: redirectUri,
      access_token: "",
      refresh_token: "",
    }

    fetch('http://api.arasu.online/api/save_apidata/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      if (response.ok) {
        // Handle successful response
        alert('Data saved successfully!');
      } else {
        // Handle errors
        alert('Error saving data');
      }
    })
    .catch(error => {
      // Handle network errors
      console.error('Error:', error);
    });

  }

  return (

    <>
    
    <HomePage/>
    
    <Box sx={{ display: "flex", justifyContent: "center", mt: 15 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: "400px",
          p: 4,
          borderRadius: "16px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
        }}
      >
        <TextField
          label="Username"
          type="tel"
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Client Id"
          type="tel"
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
        />
        <TextField
          label="Secret Key"
          type="tel"
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          value={secretKey}
          onChange={(e) => setSecretKey(e.target.value)}
        />
        <TextField
          label="Redirect Uri"
          type="tel"
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          value={redirectUri}
          onChange={(e) => setRedirectUri(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          sx={{
            mb: 1.5,
            py: 1.5,
            fontWeight: "bold",
            backgroundColor: "#88e124",
            "&:hover": {
              backgroundColor: "#7bb03e",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
    </>
  );
};

export default UserRegister;
