// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LoginPage from "./pages/LoginPage";
import ViewData from "./pages/Viewdata";
import RegisterApiDetails from "./pages/RegisterDetails";
import UserRegister from "./pages/UserApiDetailRegister";
import LogDetails from "./pages/LogDetails";
import Main from "./pages/Main";
import FifteenMinData from "./pages/fifteenMinData";
import OneMinDataPage from "./pages/oneMinData";
import Watchlist from "./pages/watchlist";
import ErrorBoundary from "./pages/ErrorBoundary";
import OptionsChain from "./pages/OptionsChain";


function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/main" element={<Main />} />
            <Route path="/LastData" element={<ViewData />} />
            <Route path="/regDetail" element={<RegisterApiDetails />} />
            <Route path="/userRegister" element={<UserRegister />} />
            <Route path="/logDetail" element={<LogDetails />} />
            <Route path="/fifteen-min-data" element={<FifteenMinData />} />
            <Route path="/one-min-data" element={<OneMinDataPage />} />
            <Route path="/watchlist" element={<Watchlist />} />
            <Route path="/options-chain" element={<OptionsChain />} />
          </Routes>
        </ErrorBoundary> 
        </header>
      </Router>
    </div>
  );
}

export default App;
