import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Button,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { DrawOutlined } from "@mui/icons-material";
import UserDataEditModel from "./UserDataEditModel";
import HomePage from "./HomePage";
import axios from "axios";

const RegisterApiDetails = () => {
  // Sample data
  const [open, setOpen] = useState(false);
  const userID = localStorage.getItem('userId');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    UserId: "",
    Username: "",
    ClientId: "",
    Secretkey: "",
    RedirectUri: "",
    RefreshToken: "",
    AccessToken: "",
  });


  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.arasu.online/api/show_apidata/?userid=${encodeURIComponent(userID)}`);
        
        const data = response.data || []; 
        setData(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [userID]);



  const handleAction = (rowData) => {
    setFormData({
      username: rowData.username,
      client_id: rowData.client_id, // Replace with actual email if available
      secretkey: rowData.secretkey, // Example field
      redirect_uri: rowData.redirect_uri,
      refresh_token: rowData.refreshToken,
      access_token: rowData.accestoken,
    });
    setOpen(true);
  };

  const handleCloseModal = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <p>Loading data...</p>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <p>Error loading data: {error.message}</p>
      </Box>
    );
  }

 

  return (

    <>
    <HomePage/>
    <Box
      sx={{
        height: "80vh", // Ensures the box fills the viewport height
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          mt: 3,
          boxShadow: 2,
          borderRadius: "3px",
          width: "90%", // Full width
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "100%",
            overflowY: "auto", // Enable vertical scrolling
            scrollBehavior: "smooth", // Smooth scrolling
            flexGrow: 1,
            width: "100%", // Full width for the table container
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { label: "User Name", value: "username" },
                  { label: "Client Id", value: "client_id" },
                  { label: "Secret Key", value: "secretkey" },
                  { label: "Redirect Uri", value: "redirect_uri" },
                  { label: "Refresh Token", value: "refresh_token" },
                  { label: "Access Token", value: "accesstoken" },
                  { label: "Edit ", value: "" },
                ].map((header) => (
                  <TableCell
                    key={header.value || header.label}
                    sx={{
                      fontWeight: "bold",
                      color: "#ffffff",
                      backgroundColor: "#83d600",
                      textAlign: "center",
                      borderLeft: "1px solid #e0e0e0",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      padding: "8px",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{
                    border: "1px solid #e0e0e0", // Set border for each row
                  }}
                >
                  
                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    {row.username}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    {row.client_id}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    {row.secretkey }
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    {row.redirect_uri}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    {row.refreshToken}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    {row.accestoken}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: "8px",
                      borderLeft: "1px solid #e0e0e0",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#8bd616",
                        color: "#ffffff",
                        padding: "2px 12px",
                        "&:hover": {
                          backgroundColor: "#acea4b",
                          color: "#000000",
                        },
                      }}
                      onClick={() => handleAction(row)}
                      startIcon={<DrawOutlined sx={{ ml: 1 }} />}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <UserDataEditModel
        open={open}
        handleClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
      />
    </Box>
    </>
  );
};

export default RegisterApiDetails;
