// src/pages/OptionsChain.js

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import axios from 'axios';
import HomePage from './HomePage';

const OptionsChain = () => {
  const [symbol, setSymbol] = useState('');
  const [strikeCount, setStrikeCount] = useState(1);
  const [expiryDates, setExpiryDates] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState('');
  const [vixData, setVixData] = useState(null);
  const [optionsData, setOptionsData] = useState([]);
  const [indexData, setIndexData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpiryLoading, setIsExpiryLoading] = useState(false);
  const [error, setError] = useState('');

  // Function to fetch expiry dates
  const handleFetchExpiryDates = async () => {
    if (!symbol || !strikeCount) {
      setError('Please enter both symbol and strike count.');
      return;
    }

    setIsExpiryLoading(true);
    setError('');
    setExpiryDates([]);
    setSelectedExpiry('');
    setVixData(null);
    setOptionsData([]);

    try {
      const response = await axios.get(
        'https://api.arasu.online/api/Data/GetOptionsChain',
        {
          params: {
            symbol: symbol,
            strikeCount: strikeCount,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );


   
      if (response.data.status === 1) {
        //const data = response.data.data.data;

        // Process expiry dates
        const expiryData = response.data.data.data.expiryData.map((item) => ({
          date: item.Date,
          expiry: item.Expiry,
        }));
        setExpiryDates(expiryData);
        setIndexData(response.data.data.data.optionsChainData[0].Ltp);

        // Store the fetched data temporarily
        // We might need to fetch options data again when expiry is selected
      } else {
        setError('Failed to fetch expiry dates.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while fetching expiry dates.');
    } finally {
      setIsExpiryLoading(false);
    }
  };

  // Function to fetch options chain data for the selected expiry
  const handleFetchOptionsChain = async () => {
    if (!selectedExpiry) {
      setError('Please select an expiry date.');
      return;
    }

    setIsLoading(true);
    setError('');
    setVixData(null);
    setOptionsData([]);

    try {
      const response = await axios.get(
        'https://api.arasu.online/api/Data/GetOptionsChain',
        {
          params: {
            symbol: symbol,
            strikeCount: strikeCount,
            expiry: selectedExpiry,
          },
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem('token')}`,
        //   },
        }
      );
   

      if (response.data.status === 1) {
        const data = response.data.data.data;

        // Set VIX data
        setVixData(response.data.data.data.indiavixData);
        

        setIndexData(response.data.data.data.optionsChainData[0]);
       
        // Set options data
        setOptionsData(response.data.data.data.optionsChainData);
      } else {
        setError('Failed to fetch options chain data.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while fetching options chain data.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Function to handle expiry change
  const handleExpiryChange = (event) => {
    setSelectedExpiry(event.target.value);
    // Optionally, fetch options data automatically when expiry is selected
    handleFetchOptionsChain();
  };



  // Function to process options data into table format
  const processOptionsData = () => {
    const processedData = {};

    optionsData.forEach((item) => {
      const strikePrice = item.Strike_price;
      const optionType = item.Option_type;

      if (!processedData[strikePrice]) {
        processedData[strikePrice] = {};
      }

      processedData[strikePrice][optionType] = item;
    });

    return processedData;
  };

  return (


    <>

<HomePage />
    <Box sx={{ p: 2, margin:2 }}>
      <Typography variant="h5" sx={{ mb: 2, mt:2, justifyContent:"center",  display:"flex", fontWeight:"700", color:"GrayText" }}>
        Options Chain
      </Typography>

      {/* Input Fields */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2, mt:2, flexWrap: 'wrap' }}>
        <TextField
          label="Symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
        />
        <TextField
          label="Strike Count"
          type="number"
          value={strikeCount}
          onChange={(e) => setStrikeCount(Number(e.target.value))}
        />
        <Button variant="contained" onClick={handleFetchExpiryDates}>
          Get Expiry Dates
        </Button>
      </Box>

      {isExpiryLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Expiry Date Selector */}
      {expiryDates.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="expiry-select-label">Expiry Date</InputLabel>
            <Select
              labelId="expiry-select-label"
              value={selectedExpiry}
              label="Expiry Date"
              onChange={handleExpiryChange}
            >
              {expiryDates.map((expiry) => (
                <MenuItem key={expiry.expiry} value={expiry.expiry}>
                  {expiry.date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleFetchOptionsChain}
            sx={{ ml: 2 }}
          >
            Get Options Chain
          </Button>
        </Box>
      )}

      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {/* VIX and Index Data */}


     


      {vixData && (
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Index Name</TableCell>
                <TableCell>LTP</TableCell>
                <TableCell>Change</TableCell>
                <TableCell>Change %</TableCell>
               
              </TableRow>
            </TableHead>
            <TableBody>
            {vixData.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.Description}</TableCell>
            <TableCell>{item.Ltp}</TableCell>
            <TableCell>{item.Ltpch}</TableCell>
            <TableCell>{item.Ltpchp}</TableCell>
          </TableRow>
        ))}
            {/* {indexData.map((item, index) => ( */}

        
                <TableRow key={indexData}>
                    <TableCell>{indexData.Description}</TableCell>
                    <TableCell>{indexData.Ltp}</TableCell>
                    <TableCell>{indexData.Ltpch}</TableCell>
                    <TableCell>{indexData.Ltpchp}</TableCell>
                </TableRow>
            {/* // ))} */}
            
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Options Chain Data */}
      {optionsData.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  Calls
                </TableCell>
                <TableCell align="center">Strike Price</TableCell>
                <TableCell align="center" colSpan={4}>
                  Puts
                </TableCell>
              </TableRow>
              <TableRow>
                {/* Call Headers */}
                <TableCell>OI</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>LTP</TableCell>
                <TableCell>Change%</TableCell>
                {/* Strike Price */}
                <TableCell>Strike Price</TableCell>
                {/* Put Headers */}
                <TableCell>LTP</TableCell>
                <TableCell>Change%</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>OI</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(processOptionsData()).map(
                ([strikePrice, data]) => (
                  <TableRow key={strikePrice}>
                    {/* Call Data */}
                    <TableCell>{data['CE']?.Oi ?? '-'}</TableCell>
                    <TableCell>{data['CE']?.Volume ?? '-'}</TableCell>
                    <TableCell>{data['CE']?.Ltp ?? '-'}</TableCell>
                    <TableCell>{data['CE']?.Ltpchp ?? '-'}</TableCell>
                    {/* Strike Price */}
                    <TableCell>{strikePrice}</TableCell>

                    {/* Put Data */}
                    <TableCell>{data['PE']?.Ltp ?? '-'}</TableCell>
                    <TableCell>{data['PE']?.Ltpchp ?? '-'}</TableCell>
                    <TableCell>{data['PE']?.Volume ?? '-'}</TableCell>
                    <TableCell>{data['PE']?.Oi ?? '-'}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
    
    </>

  
  );
};

export default OptionsChain;
