import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  Box,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoginOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Styled components

const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#9adf26",
  color: "#000000",
  top: 1,
  right: 1,
  left: 1,
  width: "95%",
  margin: "10px auto",
  borderRadius: "15px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0",
    borderRadius: "0",
  },
}));

const HomePage = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();


  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
    handleMenuClose();
  };

  const handleFyresLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        "https://api.arasu.online/api/Auth/client-data"
      );

     
      const authUrl = response.data.authUrl;
      if (authUrl) {
        window.location.href = authUrl;
      } else {
        console.error("Auth URL not found in the response.");
        alert("Failed to retrieve authentication URL.");
      }
    } catch (error) {
      console.error("Error fetching auth URL:", error);
      alert("Failed to initiate login.");
    }
  };

  return (
    <>
      {/* Navigation Bar */}
      {!isMobileOrTablet && (
        <NavBar position="fixed">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Stryve
            </Typography>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/main")}
              sx={{ marginRight: "5px" }}
            >
              Home
            </Button>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/watchlist")}
              sx={{ marginRight: "5px" }}
            >
             Watch List
            </Button>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/LastData")}
              sx={{ marginRight: "5px" }}
            >
              View Data
            </Button>

            <Button
              color="inherit"
              onClick={() => handleNavigation("/options-chain")}
              sx={{ marginRight: "5px" }}
            >
              Option Chain
            </Button>

        
            <Button
              color="inherit"
              onClick={handleMenuOpen}
              endIcon={<ExpandMoreIcon />}
              sx={{ marginRight: "5px" }}
            >
              Settings
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleNavigation("/userRegister")}>
                Register Api Details
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/regDetail")}>
                 Api Details
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/logDetail")}>
                Log Details
              </MenuItem>

              <MenuItem onClick={handleFyresLogin}>
                <Box display="flex" alignItems="center">
                  Login
                  <LoginOutlined sx={{ ml: 1 }} />
                </Box>
              </MenuItem>
            </Menu>
          </Toolbar>
        </NavBar>
      )}

      {/* Drawer for mobile and tablet menu */}
      {isMobileOrTablet && (
        <NavBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Stryve
            </Typography>
          </Toolbar>
        </NavBar>
      )}

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={() => handleNavigation("/main")}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/watchlist")}>
              <ListItemText primary="Watchlist" />
            </ListItem>
           
            <ListItem button onClick={() => handleNavigation("/LastData")}>
              <ListItemText primary="View Data" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/options-chain")}>
              <ListItemText primary="Option Chain" />
            </ListItem>

            {/* Register List Item with Dropdown */}
            <ListItem button onClick={handleMenuOpen}>
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    Settings
                    <ExpandMoreIcon sx={{ ml: 1 }} />
                  </Box>
                }
              />
            </ListItem>

            {/* Register Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleNavigation("/userRegister")}>
                Register Api Details
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/regDetail")}>
                 Api Details
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/logDetail")}>
                Log Details
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/login")}>
                <Box display="flex" alignItems="center">
                  Login
                  <LoginOutlined sx={{ ml: 1 }} />
                </Box>
              </MenuItem>
            </Menu>
          </List>
        </Box>
      </Drawer>

      <Container maxWidth="xl" sx={{ mt: 10 }}>
        {/* Add routing components here based on routes to display the selected page */}
      </Container>
    </>
  );
};

export default HomePage;
