// src/pages/Main.js

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Card,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Autocomplete,
  CircularProgress,
  ListItem,
  Typography,
} from "@mui/material";
import HomePage from "./HomePage";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import VirtualizedList from "../components/VirtualizedList";
import { debounce } from "lodash";

const Main = () => {
  const hasProcessedAuth = useRef(false);
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");
  const [dailyData, setDailyData] = useState([]);
  const [fifteenMinData, setFifteenMinData] = useState([]);
  const [oneMinData, setOneMinData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [currentHeaders, setCurrentHeaders] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const theme = useTheme();





  useEffect(() => {
      // Listen for popstate event to detect browser back navigation
      const handlePopState = () => {
          navigate('/main');
      };

      // Attach the event listener
      window.addEventListener('popstate', handlePopState);

      // Cleanup the event listener on component unmount
      return () => {
          window.removeEventListener('popstate', handlePopState);
      };
  }, [navigate]);

  // Function to extract headers dynamically
  const getHeaders = (dataArray) => {
    if (dataArray.length === 0) return [];
    const headersSet = new Set();
    dataArray.forEach((item) => {
      Object.keys(item).forEach((key) => headersSet.add(key));
    });
    const headers = Array.from(headersSet);

    return headers;
  };

  useEffect(() => {
    // Update headers whenever currentData changes
    if (currentData.length > 0) {
      const extractedHeaders = getHeaders(currentData);
     
      setCurrentHeaders(extractedHeaders);
    } else {
      setCurrentHeaders([]);
    }
  }, [currentData]);

  useEffect(() => {
    const processAuth = async () => {
      if (hasProcessedAuth.current) {
        console.log("Auth processing already done.");
        return;
      } else {
        hasProcessedAuth.current = true;
        console.log("Testing authentication...");
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const authCodeParam = url.searchParams.get("auth_code");
        const usernameParam = localStorage.getItem("userId");
       
        const trimmedAuthCode = authCodeParam ? authCodeParam.trim() : "";
        if (!trimmedAuthCode || !usernameParam) {
          console.error("Authentication code or username missing.");
          
          return;
        }
        // Remove auth parameters from URL
        window.history.replaceState({}, document.title, "/main/");
        try {
          const response = await axios.post(
            "https://api.arasu.online/api/Auth/ReceiveAuthCode",
            {
              authCode: trimmedAuthCode,
              userid: usernameParam,
            }
          );
         
        } catch (error) {
          console.error(
            "Error during authentication:",
            error.response?.data || error.message
          );
          setError(
            "An error occurred during authentication. Please try again."
          );
        }
      }
    };
    processAuth();
  }, [navigate]);

  const handleDownloadClick = async () => {
    if (!selectedSymbol || !date1 || !date2 || !date3) {
      setError("All fields are required.");
      return;
    }
    setIsLoading(true);
    setError(null);
    setStatusMsg("");
    try {

      const userId = localStorage.getItem("userId");
      const payload = {
        selectedSymbol: selectedSymbol.symTicker,
        date1: date1,
        date2: date2,
        date3: date3,
        userId: userId,
      };
    
      const response = await axios.post(
        "https://api.arasu.online/api/Data/DownloadAndProcess",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      const historicalData = response.data.historicalData || {};
    
      setDailyData(historicalData.dailyData || []);
      setFifteenMinData(historicalData.fifteenMinData || []);
      setOneMinData(historicalData.oneMinData || []);
      setStatusMsg("Data downloaded and processed successfully.");
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to download data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // New function to handle saving data to the database
  const handleSaveToDB = async () => {
    if (!selectedSymbol) {
      setError("Please select a symbol before saving data.");
      return;
    }

    if (dailyData.length === 0 && fifteenMinData.length === 0 && oneMinData.length === 0) {
      setError("No data available to save. Please download data first.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setStatusMsg("");

    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      const payload = {
        symbol: selectedSymbol.symTicker,
        dailyData: dailyData,
        fifteenMinData: fifteenMinData,
        oneMinData: oneMinData,
        userId: userId,
      };

   

      const response = await axios.post(
        "https://api.arasu.online/api/Data/SaveToDB",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

     console.log(response.data);
      setStatusMsg("Data saved to database successfully.");
    } catch (error) {
      console.error("Error saving data to DB:", error);
      setError("Failed to save data to database. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Functions to handle showing data
  const handleClickDailyData = () => {
    setCurrentData(dailyData);
   
  };

  const handleClickFifteenMinData = () => {
    setCurrentData(fifteenMinData);
   
  };

  const handleClickOneMin = () => {
    setCurrentData(oneMinData);
    
  };

  // Debounce the API call to prevent excessive requests
  const fetchSymbolOptions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim().length < 1) {
          setSymbolOptions([]);
          return;
        }
        setLoading(true);
        setSearchError(null);
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            "https://api.arasu.online/api/Symbol/Search",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { query: query },
            }
          );
         
          if (response.data && Array.isArray(response.data)) {
            setSymbolOptions(response.data);
          } else {
            setSymbolOptions([]);
          }
        } catch (err) {
          console.error("Error fetching symbol options:", err);
          setSearchError("Failed to fetch symbols.");
        } finally {
          setLoading(false);
        }
      }, 300),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    fetchSymbolOptions(newInputValue);
  };

  // Handle symbol selection
  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
    const sortedData = [...currentData].sort((a, b) => {
      const aValue =
        a[column] !== undefined && a[column] !== null ? a[column] : "";
      const bValue =
        b[column] !== undefined && b[column] !== null ? b[column] : "";

      // Convert string numbers to actual numbers for comparison
      const aNumber = parseFloat(aValue);
      const bNumber = parseFloat(bValue);
      if (!isNaN(aNumber) && !isNaN(bNumber)) {
        return isAsc ? aNumber - bNumber : bNumber - aNumber;
      }

      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();
      if (aString < bString) {
        return isAsc ? -1 : 1;
      }
      if (aString > bString) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setCurrentData(sortedData);
  };

  return (
    <>
      <HomePage />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 15 }}>
        <Box
          sx={{
            width: "530px",
            p: 4,
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
          }}
        >
          {/* Symbol Selection */}
          <Autocomplete
            options={symbolOptions}
            getOptionLabel={(option) => option.symTicker}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            loading={loading}
            ListboxComponent={VirtualizedList}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.symTicker}>
                {option.symTicker}
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Symbol"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText={
              inputValue.trim().length < 1
                ? "Type at least 1 character"
                : "No symbols found"
            }
          />
          {searchError && (
            <Typography
              variant="body2"
              color="error"
              sx={{ marginTop: theme.spacing(2), textAlign: "center" }}
            >
              {searchError}
            </Typography>
          )}
          {/* Date Inputs */}
          <TextField
            label="Select Date for Daily Close Price"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4, mt: 2 }}
            value={date1 || ""}
            onChange={(e) => setDate1(e.target.value)}
          />
          <TextField
            label="Select From Date (15m/1m Interval)"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4 }}
            value={date2 || ""}
            onChange={(e) => setDate2(e.target.value)}
          />
          <TextField
            label="Select To Date (15m/1m Interval)"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 4 }}
            value={date3 || ""}
            onChange={(e) => setDate3(e.target.value)}
          />
          {/* Action Buttons */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ flexGrow: 1, mr: 1 }}
              onClick={handleClickDailyData}
              disabled={dailyData.length === 0}
            >
              Show Daily Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ flexGrow: 1, mr: 1 }}
              onClick={handleClickFifteenMinData}
              disabled={fifteenMinData.length === 0}
            >
              Show 15 Min Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ flexGrow: 1 }}
              onClick={handleClickOneMin}
              disabled={oneMinData.length === 0}
            >
              Show 1 Min Data
            </Button>
          </Box>
          {/* Download Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 3,
              py: 1.5,
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
            onClick={handleDownloadClick}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Download Data"}
          </Button>

          {/* Save to DB Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 3,
              py: 1.5,
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
            onClick={handleSaveToDB} // Add this line
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Save To DB"}
          </Button>

          {/* Status or Error Messages */}
          {statusMsg && <p style={{ color: "green" }}>{statusMsg}</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Box>
      </Box>
      {/* Data Table */}
      <Box
        sx={{
          height: "75vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            mt: 3,
            boxShadow: 2,
            borderRadius: "3px",
            width: "98%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "100%",
              overflowY: "auto",
              scrollBehavior: "smooth",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {currentHeaders.map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        fontWeight: "bold",
                        color: "#ffffff",
                        backgroundColor: "#83d600",
                        textAlign: "center",
                        borderLeft: "1px solid #e0e0e0",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort(header)}
                    >
                      {header.replace(/([A-Z])/g, " $1").trim()}{" "}
                      {/* Add spaces before capital letters */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    {currentHeaders.map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          textAlign: "center",
                          padding: "8px",
                          borderLeft: "1px solid #e0e0e0",
                        }}
                      >
                        {row[header]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default Main;
