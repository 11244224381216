import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Link,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";



const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://api.arasu.online/api/Auth/Login", {
        username,
        password,
      });

     console.log(response.data.token);

      if (response.data.message === "Login successful") {
        localStorage.setItem("userId", response.data.user.id);
        localStorage.setItem("username", response.data.user.username);
       

        navigate("/main");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Login failed. Please try again.",
        {
          position: "top-right",
        }
      );
      console.error("Error:", error);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Card sx={{ borderRadius: 4, boxShadow: 5, width: "100%", padding: 4 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h5" component="h1" gutterBottom>
              Sign In
            </Typography>
          </Box>
          <CardContent>
            <form onSubmit={handleLogin}>
              <TextField
                label="UserName"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box display="flex" justifyContent="flex-end" mt={1} mb={2}>
                <Link href="#" underline="hover">
                  Forgot password?
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2, height: 45 }}
              >
                Sign In
              </Button>
            </form>
            <Box textAlign="center" mt={3}>
              <Typography variant="body2">
                Don’t have an account?{" "}
                <Link href="/userRegister" underline="hover" color="primary">
                  Sign Up
                </Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginPage;
