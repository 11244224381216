// src/components/VirtualizedList.js

import React from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// Define the height for each row
const ROW_HEIGHT = 36;

// Styled component for the listbox
const StyledListbox = styled('div')({
  boxSizing: 'border-box',
  '& ul': {
    padding: 0,
    margin: 0,
  },
});

const VirtualizedList = React.forwardRef(function VirtualizedList(props, ref) {
  const { children, ...other } = props;
  const itemCount = React.Children.count(children);
  const itemSize = () => ROW_HEIGHT;
  const height = Math.min(8, itemCount) * ROW_HEIGHT + 2; // Show up to 8 items without scrolling

  return (
    <StyledListbox ref={ref} {...other}>
      <VariableSizeList
        height={height}
        width="100%"
        itemSize={itemSize}
        itemCount={itemCount}
        overscanCount={5}
      >
        {({ index, style }) => React.cloneElement(children[index], { style })}
      </VariableSizeList>
    </StyledListbox>
  );
});

VirtualizedList.propTypes = {
  children: PropTypes.node,
};

export default VirtualizedList;
